<template>
  <ExamIframe :path="path"></ExamIframe>
</template>

<script>
import ExamIframe from './ExamIframe'
import { isStudent,isTeacher,isSchoolAdmin } from "@/utils/auth"

export default {
    name: "exam",
    components:{
      ExamIframe
    },
    data () {
      return {
        path: 'paper/index'
      }
    },
    created(){
      // isStudent(r=>{
      //   if(r) this.path = "paper/index"
      //   else this.path = "record/index"
      // })
    },
    methods: {}
  }
</script>
<style scoped>
  
</style>